import * as Sentry from '@sentry/vue'

// Code from https://www.lichter.io/articles/nuxt3-sentry-recipe/
export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter()
    const { public: { sentry } } = useRuntimeConfig()

    if (!sentry.dsn) {
        return
    }

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        environment: sentry.environment,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
        ],

        tracesSampleRate: sentry.tracesSampleRate,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', 'https://bokabord.se'],

        replaysSessionSampleRate: sentry.replaysSessionSampleRate,
        replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate
    })
})
