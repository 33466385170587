<template>
  <div>
    <NuxtLoadingIndicator />
    <Head>
      <!-- <Title>{{ $t('shared.meta.title') }}</Title>
      <Meta name="description" :content="$t('shared.meta.description')" />
      <Meta name="og:image" :content="useFullPath('vinspiration.jpg')" /> -->
      <Link rel="canonical" :href="canonical" />
    </Head>
    <div class="sticky z-50 top-0 w-full p-4 bg-white border-b">
      <div class="flex items-center gap-4 text-sm">
        <NuxtLink to="/">Hem</NuxtLink>
        <NuxtLink to="/kott">Kött</NuxtLink>
        <NuxtLink to="/fisk">Fisk</NuxtLink>
        <NuxtLink to="/guider">Guider</NuxtLink>
        <NuxtLink to="/mat-vin">Mat & Vin</NuxtLink>
      </div>
    </div>
    <!-- <div class="debug !text-2xs fixed z-[999] bottom-2 right-2 !bg-gray-100">
      pageviews: {{ app.pageviews }}
      <br>
      name: {{ route.name }}
      <hr class="my-2">
      categoris: {{ app.config.categories.map(cat => cat?.name)?.join(', ') }}
    </div> -->
    <!-- <AppHeader /> -->
    <!-- <AppDrawer /> -->
    <NuxtPage />
    <!-- <AppFooter /> -->
  </div>
</template>

<script setup>
const route = useRoute()
const router = useRouter()
const app = useAppStore()

// Create canonical, without query params, but with paged 
const canonical = computed(() => route?.query?.paged
  ? useFullPath(route.path) + `?paged=${route?.query?.paged}`
  : useFullPath(route.path))

// Increment page views in config
watch(() => route.query, () => {
  app.addPageview()
})

// Add initial page view and smooth scrolling behavior 
onMounted(() => {
  app.addPageview()
})

// Cleanup listener to avoid memory leaks
onUnmounted(() => {
  router.afterEach(() => {})
})

// Toggle no-scroll class on body
useHead({
  bodyAttrs: {
    class: computed(() => app.lockScroll ? 'no-scroll' : '')
  }
})
</script>