import { default as _91_46_46_46all_930i5ctWJQq5Meta } from "/vercel/path0/pages/[...all].vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexG7XwsIruPAMeta } from "/vercel/path0/pages/posts/index.vue?macro=true";
import { default as singleyuAj5bxe6HMeta } from "/vercel/path0/pages/posts/single.vue?macro=true";
import { default as _91slug_93y9qXPunWqAMeta } from "/vercel/path0/pages/products/[slug].vue?macro=true";
import { default as indexDJjD9NcZ4YMeta } from "/vercel/path0/pages/products/index.vue?macro=true";
export default [
  {
    name: "all",
    path: "/:all(.*)*",
    component: () => import("/vercel/path0/pages/[...all].vue").then(m => m.default || m)
  },
  {
    name: "start",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "posts",
    path: "/posts",
    component: () => import("/vercel/path0/pages/posts/index.vue").then(m => m.default || m)
  },
  {
    name: "posts-single",
    path: "/posts/single",
    component: () => import("/vercel/path0/pages/posts/single.vue").then(m => m.default || m)
  },
  {
    name: "products-slug",
    path: "/produkter/:slug()",
    component: () => import("/vercel/path0/pages/products/[slug].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/produkter",
    component: () => import("/vercel/path0/pages/products/index.vue").then(m => m.default || m)
  }
]