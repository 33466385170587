<template>
  <div>
    <div class="lg:pr-[14rem]">
      <div class="max-w-lg mx-auto py-6 min-h-[90vh] flex flex-col items-center justify-center">
        <div class="grid gap-8 p-4 md:p-8">
          <div class="text-center grid gap-4">
            <div class="grid gap-2">
              <h1 class="font-bold text-2xl md:text-3xl" v-html="text.heading" />
              <p class="text-sm" v-html="text.leading" />
              <button class="button-red text-sm flex justify-self-center mt-4" @click="handleError">
                {{ text.button }}
              </button>
            </div>
          </div>
        </div>
        <div class="text-2xs opacity-20 mt-4">{{ error?.statusCode }} code</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as Sentry from '@sentry/vue'

const props = defineProps({
  error: Object
})

const { t } = useI18n()
const route = useRoute()
const is404 = props?.error?.statusCode === 404

// Grep translations based on status code
const text = {
  heading: is404 ? t('error.404.heading') : t('error.500.heading'),
  leading: is404 ? t('error.404.leading') : t('error.500.leading'),
  button: is404 ? t('error.404.button') : t('error.500.button'),
}

// Handle button click
const handleError = () => clearError({ redirect: '/' })

// Send message to Sentry about 404 pages 
onMounted(() => {
  if (is404) {
    Sentry.captureMessage(`404 page - ${route.path}`, (context) => {
      context.setExtra('route', route.fullPath);
    })
  }
})
</script>
