export default {
  en: [],
  sv: [
    {
      code: 301,
      newPath: '/mat-vin/vin-till-flankstek-den-perfekta-matchningen',
      oldPaths: [
        '/vin-till-flankstek'
      ]
    }
  ]
}