import { marked } from 'marked'

// Get env attribute
export function useEnv(attribute = null) {
  const runtimeConfigPublic = useRuntimeConfig().public
  if (attribute) return runtimeConfigPublic[attribute]
  return { ...runtimeConfigPublic }
}

// Create promise delay
export function useDelay(delay) {
  return new Promise(resolve => setTimeout(resolve, delay))
}

// Get full path for request
export function useFullPath(path = '') {
  const url = useRequestURL()
  return `${url.protocol}//${url.host}${path}`
}

// Join an array with delimiter and last delimiter (x, y & z)
export function usePrettyJoin(array, delimiter = ',', lastDelimiter = '&') {
  return [
    ...array.slice(0, -2), 
    array.slice(-2).join(` ${lastDelimiter} `)
  ].join(`${delimiter} `)
}

//
// Capitalize the first letter in a string 
// 
export function useUcFirst(str = '') {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

//
// Update route query by merging current query
//
export function useUpdateQuery(object) {
  const route = useRoute()
  const router = useRouter()
  router.push({ query: { ...route.query, ...object}})
}

//
// Check if a string is a valid email
//
export function useValidateEmail(email) {
  const re = /\S+@\S+\.\S+/
  return re.test(email)
}

//
// Convert an object into a URL query string.
//
export function useObjectToQueryString(obj) {
  const keyValuePairs = []
  
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key] !== undefined) {
      const value = obj[key]
      keyValuePairs.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    }
  }
  
  return keyValuePairs.join('&')
}

// 
// Create slug from a string
// 
export function useSlugify(str = '') {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/['`´]/g, '')
    .toLowerCase()
    .replace(/ /g, '-')
}

// 
// Normalize string
// 
export function useNormalizeString(str) {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/['`´]/g, '')
    .toLowerCase()
}

//
// Filter an array of objects based on a specific attribute to remove duplicates
//
export function useUniqueBy(array, attribute) {
  if (!array?.length || !array[0] || !array[0][attribute]) return []
  const seenValues = new Set()
  
  return array.filter(obj => {
    const value = obj[attribute]
    if (!seenValues.has(value)) {
      seenValues.add(value)
      return true
    }
    return false
  })
}

//
// Use marked lib for markdown
//
export function useMarked(str) {
  return marked.parse(str)
}

//
// Asset resolver for static images, https://stackoverflow.com/a/76367548
//
export function getImage(fileName) {
  try {
    const modules = import.meta.glob('@/assets/images/**/*.{jpg,png,svg}', { eager: true, import: 'default' })
    const moduleKeys = Object.keys(modules)
    const fileSrc = moduleKeys.find(key => key.endsWith(fileName))
    return fileSrc ? modules[fileSrc] : ''
  } catch (err) {
    console.log(err)
  }
}

//
// Parse a timestamp into a dayjs object using the environment timezone
//
export function toDayjs(timestamp) {
  const dayjs = useDayjs()

  const parsed = typeof timestamp == 'number'
    ? dayjs.unix(timestamp)
    : dayjs(timestamp)

  const timezone = import.meta.server
    ? useEnv('timezone')
    : dayjs.tz.guess()

  return parsed.tz(timezone)
}