import { acceptHMRUpdate, defineStore } from 'pinia'

export const useAppStore = defineStore('app', {
  persist: false,

  state: () => ({
    uts: null,
    pageviews: 0,
    lockScroll: false,
  }),

  getters: {
    initialLoad() {
      return this.pageviews === 0
    },
    config() {
      return useNuxtApp().$configData
    },
    categories() {
      return this.config?.categories
    },
    tags() {
      return this.config?.tags
    },
  },
  
  actions: {
    addPageview() {
      this.pageviews++
    },
    updateUts() {
      this.uts = Math.floor(Date.now() / 1000)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
